import * as React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { instanceOf } from 'prop-types'
import { withCookies, Cookies } from 'react-cookie'
import { AiFillCheckCircle } from 'react-icons/ai'
import { IconContext } from 'react-icons'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Link from '@material-ui/core/Link'
import Container from '@material-ui/core/Container'
import Spinner from 'react-spinner-material'
import Layout from '../components/layout'
import Seo from '../components/seo'
import validator from 'validator'
import getStripe from '../utils/stripejs'

const BASE_API = process.env.BASE_API
const FFPROMO = process.env.FFPROMO
const FFPRODID = process.env.FFPRODID
const CustomColorCheckbox = withStyles({
  root: {
    color: '#252632',
    '&$checked': {
      color: '#4648ff',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />)

const MOBILE_BREAKPOINT = 721
const isClient = typeof window !== 'undefined'
const strongRegex = new RegExp(process.env.STRONGREGEX)
const DATAITEM = process.env.DEFAULTKEY

class SignUpPage extends React.Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  }
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      signupdetails: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confpassword: '',
        promocode: '',
        allowemails: false,
        termsandcond: false,
        subscriptionid: 'price_1KMN0wAKFfjS3iKSFRrukRql',
        subscriptiontype: 'subscription',
      },
      valid_firstname: true,
      valid_lastname: true,
      valid_email: true,
      email_used: false,
      valid_password: true,
      valid_passwordmatch: true,
      valid_promocode: true,
      valid_terms: true,
      applypromocode: false,
      promocheck: false,
      viewportWidth: isClient ? window.innerWidth : 0,
    }
    this.changeCookie = this.changeCookie.bind(this)
    this.setLoading = this.setLoading.bind(this)
    this.changeFormValue = this.changeFormValue.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.checkpromocode = this.checkpromocode.bind(this)
    this.checkpassword = this.checkpassword.bind(this)
    this.encryptdata = this.encryptdata.bind(this)
  }
  changeCookie(cookieName, newState) {
    const { cookies } = this.props
    cookies.set(cookieName, newState, { path: '/' })
    this.setState({ cookieName: newState })
  }
  setLoading(loading) {
    this.setState({ loading })
  }
  changeFormValue(event) {
    if (event.target.id === 'firstName') {
      this.setState({
        signupdetails: {
          ...this.state.signupdetails,
          firstName: event.target.value,
        },
      })
    }
    if (event.target.id === 'lastName') {
      this.setState({
        signupdetails: {
          ...this.state.signupdetails,
          lastName: event.target.value,
        },
      })
    }
    if (event.target.id === 'email') {
      this.setState({
        signupdetails: {
          ...this.state.signupdetails,
          email: event.target.value,
        },
      })
    }
    if (event.target.id === 'password') {
      this.setState({
        signupdetails: {
          ...this.state.signupdetails,
          password: event.target.value,
        },
      })
    }
    if (event.target.id === 'confpassword') {
      this.setState({
        signupdetails: {
          ...this.state.signupdetails,
          confpassword: event.target.value,
        },
      })
    }
    if (event.target.id === 'promocode') {
      this.setState({
        signupdetails: {
          ...this.state.signupdetails,
          promocode: event.target.value,
        },
      })
    }
    if (event.target.id === 'allowemails') {
      var allowemail = !this.state.signupdetails.allowemails
      this.setState({
        signupdetails: {
          ...this.state.signupdetails,
          allowemails: allowemail,
        },
      })
    }
    if (event.target.id === 'termsandcond') {
      var termsandcond = !this.state.signupdetails.termsandcond
      this.setState({
        signupdetails: {
          ...this.state.signupdetails,
          termsandcond: termsandcond,
        },
      })
    }
  }
  checkpromocode = (event) => {
    event.preventDefault()
    this.setState({ promocheck: true })
    // console.log(this.props.allCookies)

    var promocodecheck = {
      promocode: this.state.signupdetails.promocode,
      password: '',
    }
    let headers = new Headers()
    headers.append('Content-Type', 'text/html')
    headers.append('Origin', '*')
    var requestConfig = {
      method: 'POST',
      body: JSON.stringify(promocodecheck),
      mode: 'cors',
      cache: 'no-cache',
      headers: headers,
    }
    // console.log(FFPROMO)
    // console.log(event.target[0].value)
    if(FFPROMO.includes(event.target[0].value)) {
      // console.log(event.target[0].value)
      var signupdets = {
        ...this.state.signupdetails,
        promocode: event.target[0].value,
        subscriptionid: FFPRODID,
        subscriptiontype: this.props.allCookies.planDetails.type,
      }
      this.setState({
        applypromocode: true,
        signupdetails: signupdets,
      })
      this.changeCookie(
        'subscriptiontype',
        this.props.allCookies.planDetails.type,
      )
      this.changeCookie('subscriptionid',FFPRODID,)

    }
    else {
      fetch(BASE_API + 'usermgmt_checkpromocode', requestConfig)
        .then((response) => response.json())
        .then((data) => {
          if (JSON.parse(data.body) === true) {
            var signupdets = {
              ...this.state.signupdetails,
              promocode: event.target[0].value,
              subscriptionid: this.props.allCookies.planDetails.pricedetails[
                this.props.allCookies.frequency + '_promo'
              ].id,
              subscriptiontype: this.props.allCookies.planDetails.type,
            }
            this.setState({
              applypromocode: true,
              signupdetails: signupdets,
            })
            this.changeCookie(
              'subscriptiontype',
              this.props.allCookies.planDetails.type,
            )
            this.changeCookie(
              'subscriptionid',
              this.props.allCookies.planDetails.pricedetails[
                this.props.allCookies.frequency + '_promo'
              ].id,
            )
          } else {
            this.setState({ valid_promocode: false })
          }
        })
        .catch((error) => {
          console.log(error)
      })
    }
    setTimeout(() => {
      this.setState({ promocheck: false })
      console.log('Request Done')
    }, 500)
    console.log(this.state)
  }
  checkemailused = async (emailaddress) => {
    var emailused = true
    let headers = new Headers()
    headers.append('Content-Type', 'text/html')
    headers.append('Origin', '*')
    var emailcheck = {
      emailaddress: emailaddress,
      password: '',
    }
    var requestConfig = {
      method: 'POST',
      body: JSON.stringify(emailcheck),
      mode: 'cors',
      cache: 'no-cache',
      headers: headers,
    }
    await fetch(BASE_API + 'usermgmt_checkuserexists', requestConfig)
      .then((response) => response.json())
      .then((data) => {
        emailused = JSON.parse(data.body)
      })
      .catch((error) => {
        console.log(error)
      })
    return emailused
  }
  checkpassword(password) {
    var valid_password = true
    if (!strongRegex.test(password)) {
      valid_password = false
    }
    return valid_password
  }
  encryptdata(data) {
    var CryptoJS = require('crypto-js')
    var ciphertext = CryptoJS.AES.encrypt(data, DATAITEM).toString()
    return ciphertext + DATAITEM
  }
  handleSubmit = async (event) => {
    event.preventDefault()
    this.setLoading(true)
    // console.log(event.target[2].value)
    // console.log(event.target[4].value)
    // console.log(event.target[6].value)
    // console.log(event.target[8].value)
    // console.log(event.target[10].value)
    // console.log(event.target[11].value)

    var email_used = await this.checkemailused(this.state.signupdetails.email)
    var valid_firstname = true
    var valid_lastname = true
    var valid_email = true
    var valid_password = this.checkpassword(event.target[6].value)
    var valid_passwordmatch = true
    var valid_discountcode = true
    var valid_terms = true
    var valid_inputs = true
    if (email_used) {
      valid_inputs = false
    }
    if (!valid_password) {
      valid_inputs = false
    }

    if (event.target[0].value.length <= 1) {
      valid_firstname = false
      valid_inputs = false
    }
    if (event.target[2].value.length <= 1) {
      valid_lastname = false
      valid_inputs = false
    }
    if (!validator.isEmail(String(event.target[4].value))) {
      valid_email = false
      valid_inputs = false
    }
    if (event.target[6].value !== event.target[8].value) {
      valid_passwordmatch = false
      valid_inputs = false
    }
    if (event.target[11].value === 'false') {
      valid_terms = false
      valid_inputs = false
    }
    var newState = {
      ...this.state,
      valid_firstname: valid_firstname,
      valid_lastname: valid_lastname,
      valid_email: valid_email,
      valid_password: valid_password,
      valid_passwordmatch: valid_passwordmatch,
      valid_discountcode: valid_discountcode,
      email_used: email_used,
      valid_terms: valid_terms,
    }
    this.setState(newState)

    var planDetails = {}
    Object.keys(this.props.allCookies.planDetails.pricedetails).map((key) => {
      if (
        this.props.allCookies.planDetails.pricedetails[key].id ===
        this.state.subscriptionid
      ) {
        planDetails = this.props.allCookies.planDetails.pricedetails[key]
      }
    })

    if (valid_inputs) {
      // console.log(this.state)
      var price = this.state.signupdetails.subscriptionid
      var subscriptiontype = this.state.signupdetails.subscriptiontype
      if (!this.state.applypromocode) {
        try {
          price = this.props.allCookies.subscriptionid
          subscriptiontype = this.props.allCookies.planDetails.type
        } catch {
          console.log(price)
          console.log(subscriptiontype)
        }
      }
      const emaildetail = this.state.signupdetails.email
      
      this.changeCookie('userdetails', {
        firstName: this.state.signupdetails.firstName,
        lastName: this.state.signupdetails.lastName,
        email: emaildetail,
        token: this.encryptdata(event.target[6].value),
        promocode: this.state.signupdetails.promocode,
        promocodeapplied: this.state.signupdetails.promocode,
        allowExtraEmails: this.state.signupdetails.allowemails,
        termsandconditions: this.state.signupdetails.termsandcond,
        subscriptiontype: this.state.signupdetails.subscriptiontype,
        planDetails: planDetails,
      })

      try {
        const stripe = await getStripe()
        var result = await stripe.redirectToCheckout({
          mode: subscriptiontype,
          customerEmail: emaildetail,
          lineItems: [{ price, quantity: 1 }],
          successUrl: `${window.location.origin}/signupdetails/`,
          cancelUrl: `${window.location.origin}/signupproblem`,
        })

        if (result.error) {
          console.warn('Error:', result.error)
          this.setLoading(false)
        } else {
          console.log(result)
        }
      } catch (err) {
        console.log(err)
        // Need to display an error and get them to try again
      }
      // console.log(this.props.allCookies)

    } else {
      this.setLoading(false)
    }
  }
  updateWindowDimensions = () => {
    this.setState({ viewportWidth: window.innerWidth })
  }
  componentDidMount() {
    if (isClient) {
      this.updateWindowDimensions()
      window.addEventListener('resize', this.updateWindowDimensions)
    }    
    this.setState({ subscriptionid: this.props.allCookies.subscriptionid })
  }
  componentWillUnmount() {
    if (isClient)
      window.removeEventListener('resize', this.updateWindowDimensions)
  }
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const keywords = get(this, 'props.data.site.siteMetadata.keywords')
    const pageName = 'SignUp '
    var planDetails = {
      id: 'prod_KgJ7kTqCpXSKHa',
      planName: 'professional',
      type: 'subscription',
      planfeatures: [
        'Real-time Price Forecasts',
        'Quantitative & Statistical Data',
        '30 Edge Securities',
        'Daily & Hourly Timeframes',
        'Automated Trading Facility',
      ],
      pricedetails: {
        month: {
          id: 'price_1K0wVfAKFfjS3iKSiljct7Xi',
          active: true,
          currency: 'aud',
          unit_amount: 22500,
          product: {
            id: 'prod_KgJ7kTqCpXSKHa',
            name: 'Professional Plan',
          },
          recurring: {
            interval: 'month',
          },
        },
        year: {
          id: 'price_1KGdwQAKFfjS3iKSb7jzcAug',
          active: true,
          currency: 'usd',
          unit_amount: 216000,
          product: {
            id: 'prod_KgJ7kTqCpXSKHa',
            name: 'Professional Plan',
          },
          recurring: {
            interval: 'year',
          },
        },
      },
    }
    var pricedetails = {
      id: 'price_1K0wVfAKFfjS3iKSiljct7Xi',
      active: true,
      currency: 'aud',
      unit_amount: 22500,
      product: {
        id: 'prod_KgJ7kTqCpXSKHa',
        name: 'Professional Plan',
      },
      recurring: {
        interval: 'month',
      },
    }
    var subscriptionid = this.state.signupdetails.subscriptionid
    var promobutton = (
      <button type="submit" className="promocode_button">
        Apply Code
      </button>
    )
    if (this.state.applypromocode) {
      promobutton = (
        <IconContext.Provider
          value={{ size: '3vmin', className: 'promocode_tick' }}
        >
          <div>
            <AiFillCheckCircle />
          </div>
        </IconContext.Provider>
      )
    }

    if (this.props.allCookies.planDetails) {
      planDetails = this.props.allCookies.planDetails
      subscriptionid = this.props.allCookies.subscriptionid
      if (this.state.applypromocode) {
        if(FFPROMO.includes(this.state.signupdetails.promocode)){
          pricedetails = planDetails.pricedetails.onetime_friendsfamily
          subscriptionid = planDetails.pricedetails.onetime_friendsfamily.id
        }
        else {
          pricedetails =
            planDetails.pricedetails[this.props.allCookies.frequency + '_promo']
          subscriptionid =
            planDetails.pricedetails[this.props.allCookies.frequency + '_promo']
              .id
        }
      } else {
        pricedetails =
          planDetails.pricedetails[this.props.allCookies.frequency + '_base']
      }
    }

    const { viewportWidth } = this.state
    const isMobile = Boolean(viewportWidth <= MOBILE_BREAKPOINT)

    var pagewidth = {
      matches: false,
      media: '(max-width: 600px)',
      onchange: null,
    }
    if (typeof window !== 'undefined') {
      pagewidth = window.matchMedia('(max-width: 600px)')
    }

    const signuppage = (
      <Grid container spacing={0}>
        <Grid item xs={5} className="signup_container">
          <Grid container spacing={0}>
            <Grid item xs={12} className="plans_gridbox">
              <Grid container spacing={0}>
                <Grid item xs={12} className="plans_contentbox">
                  <Grid container spacing={0}>
                    <Grid item xs={12}>
                      <div className="plansheading">
                        <h2>{pricedetails.product.name}</h2>
                      </div>
                    </Grid>
                    <Grid item xs={8}>
                      <div className="plansdetail">
                        <ul className="planslist">
                          {planDetails.planfeatures.map((item) => {
                            return (
                              <li key={item} className="planslistitem">
                                {item}
                              </li>
                            )
                          })}
                        </ul>
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <Grid container spacing={0}>
                        <Grid item xs={12}>
                          <input
                            style={{ visibility: 'hidden' }}
                            readOnly
                            name="priceSelect"
                            value={pricedetails.id}
                          />
                          <div className="planspricecontainer">
                            ${(pricedetails.unit_amount / 100).toFixed(0)}
                          </div>
                        </Grid>
                        <Grid item xs={12}>
                          <div className="planspricefreq">
                            {planDetails.type === 'subscription'
                              ? 'per ' + pricedetails.recurring.interval
                              : 'one off'}
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <div className="promocode_text">
                    <h3 style={{ color: '#000000', fontWeight: 'bold' }}>
                      Promo Code
                    </h3>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="promocode_text">
                    All EdgeForecaster subscribers receive a promo-code to give
                    out to their family, friends and followers, who all receive
                    a 10% discount. Read more on our{' '}
                    <a href="/offers">Promo Code and Offers page</a>. If you
                    have been given a promo code, please enter it below:
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <form
                    id="promocode-form"
                    method="post"
                    style={{ width: '100%' }}
                    onSubmit={this.checkpromocode}
                  >
                    <Grid container spacing={0} style={{ width: '100%' }}>
                      <Grid item xs={2} sm={2} />
                      <Grid item xs={5} sm={5}>
                        <input
                          type="text"
                          id="promocode"
                          placeholder="Promo Code"
                          className="promocode_input"
                          name="promocode"
                          onChange={this.changeFormValue}
                        />
                        {!this.state.valid_promocode ? (
                          <span className="signup_validationmsg">
                            The discount code you entered is invalid.
                          </span>
                        ) : (
                          <span />
                        )}
                      </Grid>
                      <Grid item xs={1} sm={1} />
                      <Grid item xs={2} sm={2}>
                        {this.state.promocheck ? (
                          <div className="promocode_spinner">
                            <Spinner
                              radius={25}
                              color={'#4648ff'}
                              stroke={2}
                              visible={true}
                            />
                          </div>
                        ) : (
                          promobutton
                        )}
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1} />
        <Grid
          item
          xs={5}
          className="signup_container"
          style={{ paddingTop: '2vh' }}
        >
          <Container component="main" maxWidth="xs">
            <div>
              <form noValidate onSubmit={this.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      className="signup_input"
                      autoComplete="fname"
                      name="firstName"
                      variant="outlined"
                      required
                      fullWidth
                      id="firstName"
                      label="First Name"
                      onChange={this.changeFormValue}
                    />
                    {!this.state.valid_firstname ? (
                      <span className="signup_validationmsg">
                        You must enter a valid first name.
                      </span>
                    ) : (
                      <span />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      className="signup_input"
                      autoComplete="fname"
                      name="lastName"
                      variant="outlined"
                      required
                      fullWidth
                      id="lastName"
                      label="Last Name"
                      onChange={this.changeFormValue}
                    />
                    {!this.state.valid_lastname ? (
                      <span className="signup_validationmsg">
                        You must enter a valid last name.
                      </span>
                    ) : (
                      <span />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      className="signup_input"
                      autoComplete="email"
                      name="email"
                      variant="outlined"
                      required
                      fullWidth
                      id="email"
                      label="Email"
                      onChange={this.changeFormValue}
                    />
                    {!this.state.valid_email ? (
                      <span className="signup_validationmsg">
                        You must enter a valid email.
                      </span>
                    ) : (
                      <span />
                    )}
                    {this.state.email_used ? (
                      <span className="signup_validationmsg">
                        The email you entered has already been signed up.
                      </span>
                    ) : (
                      <span />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      className="signup_input"
                      autoComplete="current-password"
                      name="password"
                      variant="outlined"
                      required
                      fullWidth
                      id="password"
                      label="Password"
                      type="password"
                      onChange={this.changeFormValue}
                    />
                    {!this.state.valid_password ? (
                      <span className="signup_validationmsg">
                        Your password must include at least One(1) Uppercase,
                        One(1) Lowercase, One(1) Number, One(1) Special
                        Character and be at least Eight(8) Characters long.
                      </span>
                    ) : (
                      <span />
                    )}
                    {!this.state.valid_passwordmatch ? (
                      <span className="signup_validationmsg">
                        Your passwords must match.
                      </span>
                    ) : (
                      <span />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      className="signup_input"
                      autoComplete="confpassword"
                      name="confpassword"
                      variant="outlined"
                      required
                      fullWidth
                      id="confpassword"
                      label="Confirm Password"
                      type="password"
                      onChange={this.changeFormValue}
                    />
                    {!this.state.valid_passwordmatch ? (
                      <span className="signup_validationmsg">
                        Your passwords must match.
                      </span>
                    ) : (
                      <span />
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      className="signup_tickbox"
                      control={
                        <CustomColorCheckbox
                          value={this.state.signupdetails.allowemails}
                          name="allowExtraEmails"
                          id="allowemails"
                          onChange={this.changeFormValue}
                        />
                      }
                      label="I want to receive updates via email."
                    />
                    <FormControlLabel
                      className="signup_tickbox"
                      control={
                        <CustomColorCheckbox
                          value={this.state.signupdetails.termsandcond}
                          name="termsandconditions"
                          id="termsandcond"
                          required
                          onChange={this.changeFormValue}
                        />
                      }
                      label="I have read the Terms & Conditions"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {!this.state.valid_terms ? (
                      <span className="signup_validationmsg">
                        You must accept the Terms & Conditions.
                      </span>
                    ) : (
                      <span />
                    )}
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  {' '}
                  Sign Up{' '}
                </Button>
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <Link href="/" variant="body2">
                      {' '}
                      Already have an account? Sign in{' '}
                    </Link>
                  </Grid>
                </Grid>
              </form>
            </div>
          </Container>
        </Grid>
      </Grid>
    )
    const signuppage_mob = (
      <Grid container spacing={0}>
        <Grid item xs={12} className="signup_container">
          <Grid container spacing={0}>
            <Grid item xs={12} className="plans_gridbox">
              <Grid container spacing={0}>
                <Grid item xs={12} className="plans_contentbox">
                  <Grid container spacing={0}>
                    <Grid item xs={12}>
                      <div className="plansheading">
                        <h2>{pricedetails.product.name}</h2>
                      </div>
                    </Grid>
                    <Grid item xs={8}>
                      <div className="plansdetail">
                        <ul className="planslist">
                          {planDetails.planfeatures.map((item) => {
                            return (
                              <li key={item} className="planslistitem">
                                {item}
                              </li>
                            )
                          })}
                        </ul>
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <Grid container spacing={0}>
                        <Grid item xs={12}>
                          <input
                            style={{ visibility: 'hidden' }}
                            readOnly
                            name="priceSelect"
                            value={pricedetails.id}
                          />
                          <div className="planspricecontainer">
                            ${(pricedetails.unit_amount / 100).toFixed(0)}
                          </div>
                        </Grid>
                        <Grid item xs={12}>
                          <div className="planspricefreq">
                            {planDetails.type === 'subscription'
                              ? 'per ' + pricedetails.recurring.interval
                              : 'one off'}
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <div className="promocode_text">
                    <h3 style={{ color: '#000000', fontWeight: 'bold' }}>
                      Promo Code
                    </h3>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="promocode_text">
                    All EdgeForecaster subscribers receive a promo-code to give
                    out to their family, friends and followers, who all receive
                    a 10% discount. Read more on our{' '}
                    <a href="/offers">Promo Code and Offers page</a>. If you
                    have been given a promo code, please enter it below:
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <form
                    id="promocode-form"
                    method="post"
                    style={{ width: '100%' }}
                    onSubmit={this.checkpromocode}
                  >
                    <Grid container spacing={0} style={{ width: '100%' }}>
                      <Grid item xs={2} sm={2} />
                      <Grid item xs={5} sm={5}>
                        <input
                          type="text"
                          id="promocode"
                          placeholder="Promo Code"
                          className="promocode_input"
                          name="promocode"
                          onChange={this.changeFormValue}
                        />
                        {!this.state.valid_promocode ? (
                          <span className="signup_validationmsg">
                            The promo code you entered is invalid.
                          </span>
                        ) : (
                          <span />
                        )}
                      </Grid>
                      <Grid item xs={1} sm={1} />
                      <Grid item xs={2} sm={2}>
                        {this.state.promocheck ? (
                          <div className="promocode_spinner">
                            <Spinner
                              radius={25}
                              color={'#4648ff'}
                              stroke={2}
                              visible={true}
                            />
                          </div>
                        ) : (
                          promobutton
                        )}
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          className="signup_container"
          style={{ paddingTop: '2vh' }}
        >
          <Container component="main" maxWidth="xs">
            <div>
              <form noValidate onSubmit={this.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      className="signup_input"
                      autoComplete="fname"
                      name="firstName"
                      variant="outlined"
                      required
                      fullWidth
                      id="firstName"
                      label="First Name"
                      onChange={this.changeFormValue}
                    />
                    {!this.state.valid_firstname ? (
                      <span className="signup_validationmsg">
                        You must enter a valid first name.
                      </span>
                    ) : (
                      <span />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      className="signup_input"
                      autoComplete="fname"
                      name="lastName"
                      variant="outlined"
                      required
                      fullWidth
                      id="lastName"
                      label="Last Name"
                      onChange={this.changeFormValue}
                    />
                    {!this.state.valid_lastname ? (
                      <span className="signup_validationmsg">
                        You must enter a valid last name.
                      </span>
                    ) : (
                      <span />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      className="signup_input"
                      autoComplete="email"
                      name="email"
                      variant="outlined"
                      required
                      fullWidth
                      id="email"
                      label="Email"
                      onChange={this.changeFormValue}
                    />
                    {!this.state.valid_email ? (
                      <span className="signup_validationmsg">
                        You must enter a valid email.
                      </span>
                    ) : (
                      <span />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      className="signup_input"
                      autoComplete="current-password"
                      name="password"
                      variant="outlined"
                      required
                      fullWidth
                      id="password"
                      label="Password"
                      type="password"
                      onChange={this.changeFormValue}
                    />
                    {!this.state.valid_password ? (
                      <span className="signup_validationmsg">
                        Your password must include at least One(1) Uppercase,
                        One(1) Lowercase, One(1) Number, One(1) Special
                        Character and be at least Eight(8) Characters long.
                      </span>
                    ) : (
                      <span />
                    )}
                    {!this.state.valid_passwordmatch ? (
                      <span className="signup_validationmsg">
                        Your passwords must match.
                      </span>
                    ) : (
                      <span />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      className="signup_input"
                      autoComplete="confpassword"
                      name="confpassword"
                      variant="outlined"
                      required
                      fullWidth
                      id="confpassword"
                      label="Confirm Password"
                      type="password"
                      onChange={this.changeFormValue}
                    />
                    {!this.state.valid_passwordmatch ? (
                      <span className="signup_validationmsg">
                        Your passwords must match.
                      </span>
                    ) : (
                      <span />
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      className="signup_tickbox"
                      control={
                        <CustomColorCheckbox
                          value={this.state.signupdetails.allowemails}
                          name="allowExtraEmails"
                          id="allowemails"
                          onChange={this.changeFormValue}
                        />
                      }
                      label="I want to receive updates via email."
                    />
                    <FormControlLabel
                      className="signup_tickbox"
                      control={
                        <CustomColorCheckbox
                          value={this.state.signupdetails.termsandcond}
                          name="termsandconditions"
                          id="termsandcond"
                          required
                          onChange={this.changeFormValue}
                        />
                      }
                      label="I have read the Terms & Conditions"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {!this.state.valid_terms ? (
                      <span className="signup_validationmsg">
                        You must accept the Terms & Conditions.
                      </span>
                    ) : (
                      <span />
                    )}
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  {' '}
                  Sign Up{' '}
                </Button>
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <Link href="/" variant="body2">
                      {' '}
                      Already have an account? Sign in{' '}
                    </Link>
                  </Grid>
                </Grid>
              </form>
            </div>
          </Container>
        </Grid>
      </Grid>
    )

    return (
      <React.Fragment>
        <Seo pagename={pageName + ' ' + siteTitle} keywords={keywords} />
        <Layout data={this.props} pagewidth={pagewidth.matches}>
          {this.state.loading ? (
            <div className="spinneroverlay">
              <div className="spinnercontainer">
                <Spinner
                  radius={100}
                  color={'#4648ff'}
                  stroke={2}
                  visible={true}
                />
              </div>
            </div>
          ) : (
            <div />
          )}
          <div>
            <div className="page_wrapper plans_wrapper">
              <div className="container">
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <h1 className="generalpage_majorheading">Sign Up</h1>
                  </Grid>
                </Grid>
                {isMobile ? signuppage_mob : signuppage}
              </div>
            </div>
          </div>
        </Layout>
      </React.Fragment>
    )
  }
}

export default withCookies(SignUpPage)
export const SignUpPageQuery = graphql`
  query SignUpPageQuery {
    site {
      siteMetadata {
        title
        description
        menuLinks {
          name
          link
          cl
        }
        keywords
      }
    }
    sitePage {
      pluginCreator {
        name
      }
    }
  }
`
